




























































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  computed,
  onMounted,
} from "@vue/composition-api";
import {
  AtomButton,
  AtomText,
  AtomIcon,
  AtomLoading,
  AtomSwitcher,
  AtomSwitcherSizeEnum,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { CustomPaymentMethodForm } from "../../component/form";
import { customPaymentMethodRepo } from "../../repo";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { EDIT_CUSTOM_PAYMENT_METHOD } from "@/utils/enums/permissions";
import { useStore } from "@/store";
import { report } from "@chatfood/bug-reporter";
import { t } from "@/i18n";
import { ICustomPaymentMethod } from "@/module/custom-payment-method/domain/custom-payment-method";

const css = bemBuilder("custom-payment-method-main");

export default defineComponent({
  name: "CustomPaymentMethodMain",
  components: {
    AtomText,
    AtomIcon,
    AtomButton,
    AtomLoading,
    AtomSwitcher,
    CustomPaymentMethodForm,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { getters } = useStore();

    const isLoading = ref(false);
    const showCustomPaymentMethodForm = ref(false);

    const paymentMethods = ref<ICustomPaymentMethod[]>([]);
    const paymentMethodToBeUpdate = ref<ICustomPaymentMethod | undefined>();

    const canEdit = computed(() => {
      const permissions = getters["auth/getPermissions"];
      return permissions.includes(EDIT_CUSTOM_PAYMENT_METHOD);
    });

    onMounted(() => getCustomPaymentMethod(true));

    async function getCustomPaymentMethod(hasLoading = false) {
      if (hasLoading) {
        isLoading.value = true;
      }

      try {
        paymentMethods.value = await customPaymentMethodRepo.get({
          businessId: props.businessId,
        });
      } catch (error) {
        report(error);
        generalErrorToast();
      } finally {
        isLoading.value = false;
      }
    }

    async function onToggleState(method: ICustomPaymentMethod) {
      try {
        await customPaymentMethodRepo.update({
          businessId: props.businessId,
          paymentMethodId: method.id,
          name: method.name,
          isEnabled: !method.isEnabled,
        });

        new Toast().create({
          type: "success",
          text: t(
            "module.custom_payment_method.main.toast_success_state_message"
          ),
        });
      } catch (error) {
        report(error);
        generalErrorToast();
      }
    }

    function onSave() {
      getCustomPaymentMethod(true);
      closeFormModal();
    }

    function showFormModal(method?: ICustomPaymentMethod) {
      if (method) {
        paymentMethodToBeUpdate.value = method;
      }
      showCustomPaymentMethodForm.value = true;
    }

    function closeFormModal() {
      showCustomPaymentMethodForm.value = false;
      paymentMethodToBeUpdate.value = undefined;
    }

    return {
      t,
      css,
      onSave,
      isLoading,
      showFormModal,
      onToggleState,
      closeFormModal,
      paymentMethods,
      canEdit,
      paymentMethodToBeUpdate,
      showCustomPaymentMethodForm,
      AtomSwitcherSizeEnum,
    };
  },
});
