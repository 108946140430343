import {
  ICreateCustomPaymentMethodRequest,
  ICreateCustomPaymentMethodBodyPayload,
} from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { http } from "@/v2/repo/http";

export class CreateCustomPaymentMethodError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: CreateCustomPaymentMethod");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

/**
 * {@link https://chatfood.stoplight.io/docs/chatfood-api-dashboard/8d0ce08df23c0-create-custom-payment-method API Contract}
 */
export async function createCustomPaymentMethod(
  request: ICreateCustomPaymentMethodRequest
): Promise<void> {
  try {
    const params: ICreateCustomPaymentMethodBodyPayload = {
      name: request.name,
    };

    await http.post(
      `v1/businesses/${request.businessId}/payments/custom-methods`,
      params
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new CreateCustomPaymentMethodError(e);
  }
}
