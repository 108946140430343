













































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  PropType,
  ref,
  computed,
  watch,
} from "@vue/composition-api";
import {
  AtomModal,
  AtomButton,
  AtomButtonTypeEnum,
  AtomButtonSizeEnum,
  AtomText,
  AtomTextTypeEnum,
  OrgFormInput,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { customPaymentMethodRepo } from "../../repo";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { report } from "@chatfood/bug-reporter";
import { t } from "@/i18n";
import { ICustomPaymentMethod } from "../../domain/custom-payment-method";

const css = bemBuilder("custom-payment-method-form");

export default defineComponent({
  name: "CustomPaymentMethodForm",
  components: {
    AtomModal,
    AtomText,
    AtomButton,
    OrgFormInput,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    paymentMethodToBeUpdate: {
      type: Object as PropType<ICustomPaymentMethod>,
      default: undefined,
    },
  },
  emits: {
    close: () => true,
    save: () => true,
  },
  setup(props, { emit }) {
    const isSaving = ref(false);
    const paymentMethodName = ref("");

    watch(
      () => props.paymentMethodToBeUpdate,
      (val) => (paymentMethodName.value = val?.name ?? ""),
      { immediate: true }
    );

    const heading = computed(() =>
      !props.paymentMethodToBeUpdate
        ? t("module.custom_payment_method.form.heading_creating")
        : t("module.custom_payment_method.form.heading_updating")
    );

    const isSubmitDisabled = computed(() => !paymentMethodName.value.length);

    function updateName(value: string) {
      paymentMethodName.value = value.trim();
    }

    async function onSaving() {
      if (isSubmitDisabled.value) return;

      isSaving.value = true;

      try {
        const payload = {
          businessId: props.businessId,
          name: paymentMethodName.value,
        };

        !props.paymentMethodToBeUpdate
          ? await customPaymentMethodRepo.create(payload)
          : await customPaymentMethodRepo.update({
              ...payload,
              paymentMethodId: props.paymentMethodToBeUpdate.id,
              isEnabled: props.paymentMethodToBeUpdate.isEnabled,
            });

        onSuccess();
      } catch (e: any) {
        report(e);
        generalErrorToast();
      } finally {
        isSaving.value = false;
      }
    }

    function onSuccess() {
      emit("save");
      new Toast().create({
        type: "success",
        text: t("module.custom_payment_method.form.toast_success_message"),
      });
    }

    function onClose() {
      emit("close");
    }

    return {
      t,
      css,
      heading,
      onClose,
      onSaving,
      isSaving,
      updateName,
      isSubmitDisabled,
      paymentMethodName,
      AtomTextTypeEnum,
      AtomButtonTypeEnum,
      AtomButtonSizeEnum,
    };
  },
});
