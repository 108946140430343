import {
  IFetchCustomPaymentMethodsRequest,
  IFetchCustomPaymentMethodsContract,
} from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { http } from "@/v2/repo/http";
import { ICustomPaymentMethod } from "../../domain/custom-payment-method";

export class FetchCustomPaymentMethodsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchCustomPaymentMethods");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

/**
 * {@link https://chatfood.stoplight.io/docs/chatfood-api-dashboard/6db094aeb863a-get-custom-payment-methods API Contract}
 */
export async function fetchCustomPaymentMethods(
  request: IFetchCustomPaymentMethodsRequest
): Promise<Array<ICustomPaymentMethod>> {
  try {
    const { data } = await http.get<IFetchCustomPaymentMethodsContract>(
      `v1/businesses/${request.businessId}/payments/custom-methods`
    );

    return data.data
      .map((paymentMethod) => ({
        id: paymentMethod.id,
        name: paymentMethod.name,
        isEnabled: paymentMethod.is_enabled,
      }))
      .sort((a, b) =>
        a.name.localeCompare(b.name, "en", {
          numeric: true,
        })
      );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchCustomPaymentMethodsError(e);
  }
}
