import {
  IUpdateCustomPaymentMethodRequest,
  IUpdateCustomPaymentMethodBodyPayload,
} from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { http } from "@/v2/repo/http";

export class UpdateCustomPaymentMethodError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: UpdateCustomPaymentMethod");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

/**
 * {@link https://chatfood.stoplight.io/docs/chatfood-api-dashboard/c36baacbcf96f-update-custom-payment-method API Contract}
 */
export async function updateCustomPaymentMethod(
  request: IUpdateCustomPaymentMethodRequest
): Promise<void> {
  try {
    const params: IUpdateCustomPaymentMethodBodyPayload = {
      name: request.name,
      is_enabled: request.isEnabled,
    };

    await http.put(
      `v1/businesses/${request.businessId}/payments/custom-methods/${request.paymentMethodId}`,
      params
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new UpdateCustomPaymentMethodError(e);
  }
}
